var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_vm.$permissionAbility(_vm.REMOTE_ATTENDANCE_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Assign ")])] : _vm._e()], 2)]), _c('vue-good-table', {
    attrs: {
      "styleClass": "vgt-table table-custom-style condensed striped",
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row$user, _props$row2, _props$row2$ip_addres;
        return [props.column.field === 'format_user' ? [_c('UserCard', {
          attrs: {
            "user": props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : (_props$row$user = _props$row.user) === null || _props$row$user === void 0 ? void 0 : _props$row$user.data
          }
        })] : _vm._e(), props.column.field === 'format_ip_authorize' ? [_c('b-badge', {
          attrs: {
            "variant": props.row.ip_authorize ? 'light-success' : 'light-danger'
          }
        }, [_vm._v(" " + _vm._s(props.row.ip_authorize ? 'Enable' : 'Disable') + " ")])] : _vm._e(), props.column.field === 'format_ip' ? [_c('pre', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : (_props$row2$ip_addres = _props$row2.ip_addresses) === null || _props$row2$ip_addres === void 0 ? void 0 : _props$row2$ip_addres.join('\n')))])] : _vm._e(), props.column.field === 'action' ? _c('span', [_vm.$permissionAbility(_vm.REMOTE_ATTENDANCE_EDIT, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "Edit2Icon",
            "title": "Edit",
            "size": "16"
          }
        })], 1)] : _vm._e(), _vm.$permissionAbility(_vm.REMOTE_ATTENDANCE_DELETE, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "TrashIcon",
            "title": "Delete",
            "size": "16"
          }
        })], 1)] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-remote-attendance-form",
      "centered": "",
      "title": 'Create',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "remoteAttendanceValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Work Type",
      "label-for": "work-type"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "work-type",
      "vid": "work-type",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
          attrs: {
            "options": _vm.workTypeValueOption,
            "reduce": function reduce(item) {
              return item.value;
            },
            "label": "name",
            "placeholder": "Select Work Type"
          },
          model: {
            value: _vm.selectWorkType,
            callback: function callback($$v) {
              _vm.selectWorkType = $$v;
            },
            expression: "selectWorkType"
          }
        })];
      }
    }])
  })], 1), _c('b-form-group', {
    class: !_vm.selectWorkType ? 'required-label' : '',
    attrs: {
      "label": "Division",
      "label-for": "division-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "division",
      "vid": "division_id",
      "rules": _vm.selectWorkType ? '' : 'required'
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "id": "division-id",
            "placeholder": "Select Division",
            "options": _vm.divisionsOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name"
          },
          on: {
            "input": _vm.onChangeDivision
          },
          model: {
            value: _vm.selectDivisionId,
            callback: function callback($$v) {
              _vm.selectDivisionId = $$v;
            },
            expression: "selectDivisionId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _c('small', {
          staticClass: "text-primary"
        }, [_vm._v(_vm._s(_vm.policyMapDivisionTooltip))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Department",
      "label-for": "department-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "department",
      "vid": "department_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "department-id",
            "placeholder": "Select Department",
            "options": _vm.departmentsOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "disabled": _vm.departmentsOption.length > 0 ? false : true,
            "multiple": ""
          },
          on: {
            "input": _vm.onChangeDepartment
          },
          model: {
            value: _vm.selectDepartmentsIds,
            callback: function callback($$v) {
              _vm.selectDepartmentsIds = $$v;
            },
            expression: "selectDepartmentsIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _c('small', {
          staticClass: "text-primary"
        }, [_vm._v(_vm._s(_vm.policyMapDepartmentTooltip))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Employee",
      "label-for": "user-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "Employees",
      "vid": "user_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-select', {
          attrs: {
            "id": "user-id",
            "placeholder": "Select Employee",
            "options": _vm.usersOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "disabled": _vm.usersOption.length > 0 ? false : true,
            "multiple": "",
            "clearable": ""
          },
          on: {
            "input": _vm.onChangeUsersTooltip
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectUsersIds,
            callback: function callback($$v) {
              _vm.selectUsersIds = $$v;
            },
            expression: "selectUsersIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _c('small', {
          staticClass: "text-primary"
        }, [_vm._v(_vm._s(_vm.policyMapUserTooltip))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "IP Authorize",
      "label-for": "ip-authorize"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "ip-authorize",
      "vid": "ip_authorize",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-select', {
          staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
          attrs: {
            "options": _vm.ip_authorized_options,
            "reduce": function reduce(item) {
              return item.value;
            },
            "label": "name",
            "placeholder": "Select Ip Authorize"
          },
          model: {
            value: _vm.ip_authorize,
            callback: function callback($$v) {
              _vm.ip_authorize = $$v;
            },
            expression: "ip_authorize"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    class: _vm.ip_authorize ? 'required-label' : '',
    attrs: {
      "label": "IP",
      "label-for": "ip"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "ip",
      "vid": "ip_addresses",
      "rules": _vm.ip_authorize ? 'required' : ''
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "id": "ip",
            "placeholder": "Type Here",
            "label": "ip",
            "multiple": "",
            "taggable": "",
            "push-tags": ""
          },
          model: {
            value: _vm.ip_address,
            callback: function callback($$v) {
              _vm.ip_address = $$v;
            },
            expression: "ip_address"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-remote-attendance-edit-form",
      "centered": "",
      "title": 'Update',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenEditModal
    }
  }, [_c('validation-observer', {
    ref: "remoteAttendanceEditValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationEditForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "IP Authorize",
      "label-for": "ip-authorize"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "ip-authorize",
      "vid": "ip_authorize",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('v-select', {
          staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
          attrs: {
            "options": _vm.ip_authorized_options,
            "reduce": function reduce(item) {
              return item.value;
            },
            "label": "name",
            "placeholder": "Select Ip Authorize"
          },
          model: {
            value: _vm.ip_authorize,
            callback: function callback($$v) {
              _vm.ip_authorize = $$v;
            },
            expression: "ip_authorize"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    class: _vm.ip_authorize ? 'required-label' : '',
    attrs: {
      "label": "IP",
      "label-for": "ip_addresses"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "ip_addresses",
      "vid": "ip_addresses",
      "rules": _vm.ip_authorize ? 'required' : ''
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "id": "ip_addresses",
            "placeholder": "Type Here",
            "label": "ip_addresses",
            "multiple": "",
            "taggable": "",
            "push-tags": ""
          },
          model: {
            value: _vm.ip_address,
            callback: function callback($$v) {
              _vm.ip_address = $$v;
            },
            expression: "ip_address"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isFormEditSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }